<template>
  <div class="product-containter">
    <nav-menu></nav-menu>
    <div class="product-body">
      <div class="product-apply flex-a flex-b">
        <div class="product-left">
          <!-- <div>电子银行保函</div>
          <div>纯信用免保证金、额度高可循环、线上办、费率低、秒开立</div> -->
        </div>
        <!-- <div class="product-right">立即申请</div> -->
      </div>
      <!-- <div class="product-list flex-a">
        <div class="title">下列项目可提升额度<span>289500.00</span>元</div>
      </div> -->
      <div class="containter flex">
        <div class="apply-list">
          <el-card shadow="hover" v-for="item in list" :key="item.id">
            <div class="apply-head">
              <div class="apply-title">{{ item.title }}</div>
              <div class="apply-time">中标日期：{{ item.time }}</div>
              <div class="apply-money">中标金额：{{ item.money }}</div>
              <div class="apply-proNo">项目编号：{{ item.proNo }}</div>
            </div>
            <div class="apply-foot flex-a flex-b">
              <div>最高可借：{{ item.max }}</div>
              <div><span>申请</span></div>
            </div>
          </el-card>
          <el-card shadow="hover" class="apply-box">
            <div class="title">{{title}}</div>
            <div class="content">
              <i class="el-icon-warning" style="color:#1595EF"></i>
              <span>未能获取符合要求的中标项目（仅展示30天内中标的项目）</span>
            </div>
            <div class="tip">
              点击“ <span><i class="el-icon-refresh" style="color:#1595EF"></i>刷新</span> ”，可重新获取中标项目的信息；多次刷新仍未显示中标项目信息，可主动添加
            </div>
            <div class="apply-add flex-a" @click="toAddProject"><img src="./../assets/add.png" alt=""><span>添加项目并申请额度</span></div>
          </el-card>
          <div class="page-list" v-if="list.length>0">
            <el-pagination background layout="prev, pager, next" @current-change="handleCurrentChange" :total="20">
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
    <foot></foot>
  </div>
</template>

<script>
export default {
  name: 'apply',
  data() {
    return {
      title:localStorage.getItem('nickName'),
      list: [
        // {
        //   id: 1,
        //   title: '浙江金业管理咨询有限公司关于湖州市吴兴区房产交易管理中心白蚁防治服务项目的公开招标公告',
        //   time: '2022-01-02',
        //   money: '96500.00',
        //   proNo: 'ZJJY2022-119 ',
        //   max: '289500.00'
        // },
        // {
        //   id: 2,
        //   title: '浙江金业管理咨询有限公司关于湖州市吴兴区房产交易管理中心白蚁防治服务项目的公开招标公告',
        //   time: '2022-01-02',
        //   money: '96500.00',
        //   proNo: 'ZJJY2022-119 ',
        //   max: '289500.00'
        // },
        // {
        //   id: 3,
        //   title: '浙江金业管理咨询有限公司关于湖州市吴兴区房产交易管理中心白蚁防治服务项目的公开招标公告',
        //   time: '2022-01-02',
        //   money: '96500.00',
        //   proNo: 'ZJJY2022-119 ',
        //   max: '289500.00'
        // },
        // {
        //   id: 4,
        //   title: '浙江金业管理咨询有限公司关于湖州市吴兴区房产交易管理中心白蚁防治服务项目的公开招标公告',
        //   time: '2022-01-02',
        //   money: '96500.00',
        //   proNo: 'ZJJY2022-119 ',
        //   max: '289500.00'
        // },
        // {
        //   id: 5,
        //   title: '浙江金业管理咨询有限公司关于湖州市吴兴区房产交易管理中心白蚁防治服务项目的公开招标公告',
        //   time: '2022-01-02',
        //   money: '96500.00',
        //   proNo: 'ZJJY2022-119 ',
        //   max: '289500.00'
        // },
        // {
        //   id: 6,
        //   title: '浙江金业管理咨询有限公司关于湖州市吴兴区房产交易管理中心白蚁防治服务项目的公开招标公告',
        //   time: '2022-01-02',
        //   money: '96500.00',
        //   proNo: 'ZJJY2022-119 ',
        //   max: '289500.00'
        // }
      ]
    }
  },
  created() {
    if(!localStorage.getItem('token')){
      this.$alert('请先登录!', '提示', {
          confirmButtonText: '确定',
          callback: action => {
            this.$router.push('/advance')
          }
        });
    }
  },
  mounted() {
  },
  destroyed() {
  },
  methods: {
    //添加项目并申请额度
    toAddProject(){
      this.$router.push({path:'/addProject',query:{}})
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
    }
  },
  computed: {

  }
}
</script>

<style lang="less" scoped>
.product-containter {
  background: #F0F2F5;
  min-height: 100vh;

  .product-body {
    padding: 20px 0;

    .product-apply {
      box-sizing: border-box;
      width: 60%;
      height: 260px;
      margin: 0 auto 10px;
      background: url(./../assets/advance.png)no-repeat 0 0;
      background-size: 100%;
      color: #fff;
      font-size: 18px;

      .product-left {
        margin: 20px 50px;

        div:first-child {
          margin-bottom: 10px;
          font-weight: bold;
          font-size: 26px;
        }
      }

      .product-right {
        margin: 50px 80px 0 0;
        background: url(./../assets/btn_bg.png)no-repeat 0 0;
        background-size: 100%;
        width: 180px;
        height: 100px;
        padding-left: 45px;
        box-sizing: border-box;
        line-height: 40px;
        font-size: 15px;
      }

      // .product-right:hover {
      //   cursor: pointer;
      // }
    }

    .product-list {
      box-sizing: border-box;
      width: 60%;
      margin: 0 auto 20px;

      .title {
        font-weight: bold;
        margin-right: 60px;

        span {
          color: #206DDD;
        }
      }
    }

    .containter {
      padding: 20px;

      .apply-list {
        width: 100%;
        text-align: center;
        .el-card {
          display: inline-block;
          // width: 32.5%;
          width: 80%;
          box-sizing: border-box;
          margin-bottom: 10px;
          align-items: flex-start;
        }

        .apply-add {
          color: #1D75F5;
          font-weight: bold;

          img {
            width: 25px;
            margin-right: 10px;
          }
        }

        .apply-add:hover {
          cursor: pointer;
        }

        .el-card:nth-child(3n+2) {
          margin: 0 1.25% 10px;
        }

        .apply-title {
          font-weight: bold;
          margin-bottom: 20px;
        }

        .apply-time,
        .apply-money,
        .apply-proNo {
          display: inline-block;
          width: 50%;
          text-align: left;
          box-sizing: border-box;
          font-size: 15px;
          margin-bottom: 5px;
          color: #666;
          white-space: nowrap;
        }

        .apply-foot {
          margin-top: 10px;
          padding-top: 10px;
          border-top: 1px solid #F9F9F9;
          font-weight: bold;

          span {
            white-space: nowrap;
            font-size: 12px;
            color: #fff;
            display: inline-block;
            width: 40px;
            height: 20px;
            text-align: center;
            line-height: 20px;
            background: #185DFE;
            border-radius: 5px;
            font-weight: normal;
          }

          span:hover {
            cursor: pointer;
          }
        }

        .page-list {
          margin: 20px auto;
          text-align: center;
        }
      }
    }
  }
  .apply-box{
    .el-card__body{
      padding: 0 0 20px;
    }
    .title{
      font-weight: bold;
      font-size: 20px;
      text-align: center;
      height: 80px;
      line-height: 80px;
      background: linear-gradient(to bottom,#FFDA6C,#FFE189,#FFF2CC,#FFFFFF 95%);
    }
    .content{
      margin: 40px 0 20px;
      color:#404040;
    }
    .tip{
      color: #A1A1A1;
      margin-bottom: 20px;
      padding: 0 25%;
      span{
        text-decoration: underline;
        color: #1595EF;
      }
      span:hover{
        cursor: pointer;
      }
    }
    .apply-add{
      border: 1px solid #DFDEDE;
      border-radius: 10px;
      padding: 10px;
      box-sizing: border-box;
      box-shadow: 0 0 10px #ccc;
      font-size: 15px;
      width: 50%;
      margin: 0 auto;
    }
  }
}
</style>
